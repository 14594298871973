import { iUsePlayersAgents } from "./models/hookInterface";
import { useAppDispatch, useAppSelector } from "../../store";
import { useLanguage } from "../localisation/useLanguage";
import { useUser } from "../user/useUser";
import Config, { getApiHost } from "../../config";
import { useCallback } from "react";
import { ManagePlayersAgentsFilter } from "./models/filter";
import {
    changeUserBanState,
    setPlayersAgentLoader, setPlayersAgents,
    setPlayersAgentsCurrentPage,
    setPlayersAgentsFilter,
    setPlayersAgentsItemsPerPage, setPlayersAgentsTotalRecords, updateUserBalance, updateUserDepositRate
} from "./managePlayersAndAgentsSlice";
import {updateUserBalance as updateLoggedUserBalance } from "../user/userSlice";
import { PlayerAgentLoaders } from "./models/loaders";
import axios, { AxiosError } from "axios";
import {
    BanUserResponse,
    CreateUserResponse,
    DepositMoneyResponse, GetUserNamesResponse,
    setDepositRateResponse,
    ShowUsersResponse
} from "./models/responses";
import { convertUnixToCompactLocaleDate } from "../common_funcs";
import { User, UserRole } from "../user/user";
import { stringToUserRole } from "../user/userFuncs";
import { useAgents } from "../agents/useAgents";
import { addAgentAfterCreating } from "../agents/AgentsSlice";
import { ApiResponse } from "../apiResponseModels";

export const usePlayersAgents = (): iUsePlayersAgents => {
    const dispatch = useAppDispatch();
    const { setLocalizedError, handleNetworkErrors } = useLanguage();
    const state = useAppSelector(state => state.managePlayersAgents);
    const { token, user } = useUser();
    const { selectedAgent } = useAgents()
    const apiURL = getApiHost()

    const loadUsers = useCallback((page: number, itemsPerPage: number, parentID?: number|null,  filter?: ManagePlayersAgentsFilter) => {
        let filterForSending = state.filter
        if (filter) {
            if (
                filter.toTime !== state.filter.toTime
                || filter.fromTime !== state.filter.fromTime
                || filter.orderBy !== state.filter.orderBy
                || filter.userRole !== state.filter.userRole
                || filter.sortDirection !== state.filter.sortDirection
                || filter.username !== state.filter.username
            ) {
                dispatch(setPlayersAgentsFilter(filter))
                filterForSending = filter
            }
        }
        let itemsPerPageToSend = state.pageSize
        if (itemsPerPage !== state.pageSize) {
            dispatch(setPlayersAgentsItemsPerPage(itemsPerPage))
            itemsPerPageToSend = itemsPerPage
        }
        let pageToSend = state.currentPage
        if (page !== state.currentPage) {
            dispatch(setPlayersAgentsCurrentPage(page))
            pageToSend = page
        }

        if(token && user && apiURL) {
            dispatch(setPlayersAgentLoader({
                isLoading: true,
                loader: PlayerAgentLoaders.isShowUsersLoading
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ShowUsers')
            data.append('token', token)

            data.append('page', pageToSend.toString())
            data.append('pagesize', itemsPerPageToSend.toString())

            data.append('viewtype', 'tree')

            if (filterForSending.fromTime !== null) {
                data.append('fromtime', filterForSending.fromTime.toString())
            }
            if (filterForSending.toTime !== null) {
                data.append('totime', filterForSending.toTime.toString())
            }
            if (filterForSending.orderBy !== null) {
                const direction = filterForSending.sortDirection ?? 'DESC'
                data.append('orderby', filterForSending.orderBy + ` ${direction}`)
            }
            if (filterForSending.userRole !== null) {
                data.append('userrole', filterForSending.userRole)
            }
            if (filterForSending.username !== null) {
                data.append('username', filterForSending.username)
            }


            data.append('parentid', (parentID ?? user.user_id).toString())


            axios
                .post<ShowUsersResponse>(apiURL, data)
                .then(response => {
                    const { success, error, data: usersList, total_users } = response.data
                    if (success) {
                        if (usersList) {
                            usersList.forEach(user => {
                                user.registration_time = convertUnixToCompactLocaleDate(user.registration_time_unix)
                            })
                            dispatch(setPlayersAgents(usersList))
                        }
                        if (total_users) {
                            dispatch(setPlayersAgentsTotalRecords(total_users))
                        }

                    }
                    else if (error) {
                        dispatch(setPlayersAgents([]))
                        if (error.code !== 93) {
                            setLocalizedError(error)
                        }
                    }
                })
                .catch((error: Error | AxiosError) => {
                    dispatch(setPlayersAgents([]))
                    handleNetworkErrors(error)
                })
                .finally(() => {
                    dispatch(setPlayersAgentLoader({
                        isLoading: false,
                        loader: PlayerAgentLoaders.isShowUsersLoading
                    }))
                })
        }

    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, state.currentPage, state.filter, state.pageSize, token, user])

    const createUser = useCallback((userToCreate: User, password: string, onSuccess = () => {
    }) => {
        if (token && apiURL && user) {
            dispatch(setPlayersAgentLoader({
                isLoading: true,
                loader: PlayerAgentLoaders.isCreateUserLoading
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'CreateUser')
            data.append('token', token)
            data.append('username', userToCreate.user_name)
            data.append('password', password)
            data.append('userrole', userToCreate.user_role)
            data.append('currency', userToCreate.user_currency ?? 'ARS')

            if (userToCreate.user_email) {
                data.append('email', userToCreate.user_email)
            }
            if (userToCreate.user_firstname) {
                data.append('firstname', userToCreate.user_firstname)
            }
            if (userToCreate.user_lastname) {
                data.append('lastname', userToCreate.user_lastname)
            }
            if (stringToUserRole(userToCreate.user_role) === UserRole.AGENT) {
                if (userToCreate.deposit_enabled) {
                    data.append('depositenabled', userToCreate.deposit_enabled ? 'true' : 'false')
                }
                if (userToCreate.withdrawal_enabled) {
                    data.append('withdrawenabled', userToCreate.withdrawal_enabled ? 'true' : 'false')
                }
                if (userToCreate.only_players) {
                    data.append('onlyplayers', userToCreate.only_players ? 'true' : 'false')
                }
            }

            axios
                .post<CreateUserResponse>(apiURL, data)
                .then(response => {
                    const { success, error, user: newUser } = response.data
                    if (success) {
                        const parentID = selectedAgent ? selectedAgent.user_id : user.user_id
                        loadUsers(state.currentPage, state.pageSize, parentID, state.filter)
                        if (stringToUserRole(userToCreate.user_role) === UserRole.AGENT) {
                            dispatch(addAgentAfterCreating(newUser))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPlayersAgentLoader({
                        isLoading: false,
                        loader: PlayerAgentLoaders.isCreateUserLoading
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, loadUsers, selectedAgent, setLocalizedError, state.currentPage, state.filter, state.pageSize, token, user])

    const sendDeposit = useCallback((recipientID: number, amount: number, parentID: number, currency: string, onSuccess = () => {}) => {
        if (token && apiURL && user) {
            dispatch(setPlayersAgentLoader({
                isLoading: true,
                loader: PlayerAgentLoaders.isDepositSending
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'DepositMoney')
            data.append('token', token)
            data.append('childid', recipientID.toString())
            data.append('amount', amount.toString())
            data.append('currency', currency)

            axios
                .post<DepositMoneyResponse>(apiURL, data)
                .then(response => {
                    const { success, error, child_balance, parent_balance } = response.data
                    if (success) {
                        dispatch(updateUserBalance({user_id: parentID, balance: parent_balance}))
                        dispatch(updateUserBalance({user_id: recipientID, balance: child_balance}))
                        if (user.user_id === parentID) {
                            dispatch(updateLoggedUserBalance(parent_balance))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPlayersAgentLoader({
                        isLoading: false,
                        loader: PlayerAgentLoaders.isDepositSending
                    }))
                })

        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token, user])

    const withdrawMoney = useCallback((childID: number, amount: number, parentID: number, onSuccess = () => {}) => {
        if (token && apiURL && user) {
            dispatch(setPlayersAgentLoader({
                isLoading: true,
                loader: PlayerAgentLoaders.isWithdrawLoading
            }))

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'WithdrawMoney')
            data.append('token', token)
            data.append('childid', childID.toString())
            data.append('amount', amount.toString())

            axios
                .post<DepositMoneyResponse>(apiURL, data)
                .then(response => {
                    const { success, error, child_balance, parent_balance } = response.data
                    if (success) {
                        dispatch(updateUserBalance({user_id: parentID, balance: parent_balance}))
                        dispatch(updateUserBalance({user_id: childID, balance: child_balance}))
                        if (user.user_id === parentID) {
                            dispatch(updateLoggedUserBalance(parent_balance))
                        }
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    dispatch(setPlayersAgentLoader({
                        isLoading: false,
                        loader: PlayerAgentLoaders.isWithdrawLoading
                    }))
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token, user])

    const saveDepositRate = useCallback((userId: number, rate: number, onLoadingChanged?: (isLoading: boolean) => void, onSuccess?: () => void) => {
        if (token && apiURL) {
            if (onLoadingChanged) {
                onLoadingChanged(true)
            }

            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'SetDepositRate')
            data.append('token', token)
            data.append('userid', userId.toString())
            data.append('rate', rate.toString())

            axios
                .post<setDepositRateResponse>(apiURL, data)
                .then(response => {
                    const { success, error, data } = response.data
                    if (success) {
                        if (onSuccess) {
                            if (data) {
                                dispatch(updateUserDepositRate({
                                    user_id: data.user_id,
                                    deposit_rate: data.deposit_rate
                                }))
                            }
                            onSuccess()
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    if (onLoadingChanged) {
                        onLoadingChanged(false)
                    }
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token])

    const banUser = useCallback((user_id: number, banned: boolean, onLoadingChanged?: (isLoading: boolean) => void, onSuccess?: (isChecked: boolean) => void) => {
        if (token && apiURL) {
            if (onLoadingChanged) {
                onLoadingChanged(true)
            }
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'BanUser')
            data.append('token', token)
            data.append('userid', user_id.toString())
            data.append('ban', banned.toString())

            axios.post<BanUserResponse>(apiURL, data)
                .then(response => {
                    const { success, error, ban } = response.data
                    if (success) {
                        if (ban !== undefined) {
                            onSuccess && onSuccess(ban)
                            dispatch(changeUserBanState({ user_id, banned: ban }))
                        }
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    if (onLoadingChanged) {
                        onLoadingChanged(false)
                    }
                })
        }
    }, [apiURL, dispatch, handleNetworkErrors, setLocalizedError, token])

    const changeUserPassword = useCallback((childID: number, newPassword: string, onSuccess: () => void = () => {}, onLoadingChanged: (isLoading: boolean) => void = () => {}) => {
        if (token && apiURL) {
            onLoadingChanged(true)
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'ChangePassword')
            data.append('token', token)

            data.append('password', 'default')
            data.append('newpassword', newPassword)
            data.append('childid', childID.toString())

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    onLoadingChanged(false)
                })
        }
    }, [token, apiURL, handleNetworkErrors, setLocalizedError])

    const updateUserInfo = useCallback((newUserInfo: User, onSuccess: () => void = () => {}, onLoadingChanged: (isLoading: boolean) => void = () => {}) => {
        if (token && user && apiURL) {
            onLoadingChanged(true)
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'UpdateUserInformation')
            data.append('token', token)
            data.append('childid', newUserInfo.user_id.toString())
            data.append('useremail', newUserInfo.user_email ?? '')
            data.append('userphone', newUserInfo.user_phone ?? '')
            data.append('userfirstname', newUserInfo.user_firstname ?? '')
            data.append('userlastname', newUserInfo.user_lastname ?? '')
            data.append('depositenabled', newUserInfo.deposit_enabled ? 'true' : 'false')
            data.append('withdrawenabled', newUserInfo.withdrawal_enabled ? 'true' : 'false')
            data.append('onlyplayers', newUserInfo.only_players ? 'true' : 'false')

            axios.post<ApiResponse>(apiURL, data)
                .then(response => {
                    const { success, error } = response.data
                    if (success) {
                        onSuccess()
                    }
                    if (error) {
                        setLocalizedError(error)
                    }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    onLoadingChanged(false)
                })
        }
    }, [apiURL, handleNetworkErrors, setLocalizedError, token, user])

    const getUsernames = useCallback((nameBeginning: string, onSuccess: (names: string[]) => void, onLoadingChanged?: (isLoading: boolean) => void) => {
        if (token && apiURL) {
            onLoadingChanged?.(true)
            axios.create({ ...Config.axiosConfig })

            const data = new FormData();
            data.append('action', 'GetUserNames')
            data.append('token', token)
            data.append('username', nameBeginning)

            axios.post<GetUserNamesResponse>(apiURL, data)
                .then(response => {
                    const { success, usernames } = response.data
                    if (success && usernames) {
                        onSuccess(usernames)
                    }
                    // if (error) {
                    //     setLocalizedError(error)
                    // }
                })
                .catch(handleNetworkErrors)
                .finally(() => {
                    onLoadingChanged?.(false)
                })
        }
    }, [apiURL, handleNetworkErrors, token])

    return {
        ...state,
        loadUsers,
        createUser,
        sendDeposit,
        withdrawMoney,
        saveDepositRate,
        banUser,
        changeUserPassword,
        updateUserInfo,
        getUsernames
    }
}