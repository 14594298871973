import { Card, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { FC, useState, MouseEvent } from "react";
import { User } from "../../../features/user/user";
import { UserRoleChip } from "../../manage_users/UserRoleChip";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { alpha } from "@mui/system";
import { theme } from "../../../themes/golden_dark/theme";
import { Link } from "react-router-dom";
import { BanUser } from "../../commons/ban_user/BanUser";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface PlayersNAgentsProps {
    user: User
    onDepositClick?: (user: User) => void
    onWithdrawClick?: (user: User) => void
    onEditClick?: (user: User) => void
    onDepositRateEditClick?: (user: User) => void
    isParent?: boolean
}

export const PlayersNAgentsCard: FC<PlayersNAgentsProps> = ({ user, onDepositClick, onWithdrawClick, onEditClick, onDepositRateEditClick, isParent = false}) => {
    const { languagePack: {pack: {manageUsers: lang, playersAgents: playersAgentsLang}}} = useLanguage()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action: 'deposit' | 'withdraw' | 'edit' | 'editDepositRate') => {
        handleMenuClose();
        switch (action) {
            case 'deposit':
                onDepositClick && onDepositClick(user);
                break;
            case 'withdraw':
                onWithdrawClick && onWithdrawClick(user);
                break;
            case 'edit':
                onEditClick && onEditClick(user);
                break;
            case 'editDepositRate':
                onDepositRateEditClick && onDepositRateEditClick(user);
                break;
        }
    };

    return <Card sx={{pt: 0, pb: '10px', px: '2vw', backgroundColor: isParent ? alpha(theme.palette.primary.main, 0.4) : 'initial'}}>
        <Stack gap={'5px'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    {user.user_name}
                <Stack direction={'row'} alignItems={'center'}>
                    <UserRoleChip userRole={user.user_role}/>
                    <IconButton disabled={isParent} onClick={handleMenuOpen}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => handleMenuItemClick('deposit')}>{playersAgentsLang.depositBtn}</MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('withdraw')}>{playersAgentsLang.withdrawBtn}</MenuItem>
                        <MenuItem disabled={user.user_role !== 'player'}><Link to={`/game_history?username=${user.user_name}`}>{playersAgentsLang.gameHistoryBTN}</Link></MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('edit')}>{playersAgentsLang.editUserBTN}</MenuItem>
                        {user.user_role === 'agent' && <MenuItem
                            onClick={() => handleMenuItemClick('editDepositRate')}>{playersAgentsLang.editDepositRate}</MenuItem>}
                        <MenuItem>
                            <BanUser user={user}/>
                        </MenuItem>
                    </Menu>
                </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.balanceTableHeader}:
                    </Typography>
                    <Typography fontWeight={700} fontSize={'18px'} lineHeight={0.8}>
                        {convertCentsToCurrencyString(user.user_balance, true)}
                    </Typography>
                </Stack>
                <Stack direction={'row'}>
                    {!isParent && <IconButton color={'primary'} onClick={() => onDepositClick && onDepositClick(user)}>
                        <AddCircleIcon fontSize={'large'}/>
                    </IconButton>}
                    {!isParent && <IconButton color={'primary'} onClick={() => onWithdrawClick && onWithdrawClick(user)}>
                        <RemoveCircleOutlineIcon fontSize={'large'}/>
                    </IconButton>}
                </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack>
                    {user.user_role === 'agent' && <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.commissionTableHeader}:
                    </Typography>}
                    {user.user_role === 'agent' && <Typography color={'secondary'} fontWeight={500} fontSize={'10px'} lineHeight={0.8}>
                        {user.deposit_rate * 100}%
                    </Typography>}
                </Stack>
                <Stack alignItems={'end'}>
                    <Typography fontWeight={300} fontSize={'10px'}>
                        {lang.dateTableHeader}:
                    </Typography>
                    <Typography fontWeight={500} fontSize={'10px'} lineHeight={0.8}>
                    {user.registration_time?.split(' ')[0]}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    </Card>
}