import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import {
    Alert,
    Box,
    Button, Menu, MenuItem,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { SortedCellPlayersAgents } from "./SortedCellPlayersAgents";
import { SortingPlayerAgent } from "../../features/manage_players_agents/models/sorting";
import { usePlayersAgents } from "../../features/manage_players_agents/usePlayersAgents";
import { useQuery } from "../../features/common_funcs/query_hook/useQuery";
import { convertCentsToCurrencyString } from "../../features/common_funcs";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserRoleChip } from "../manage_users/UserRoleChip";
import { useLanguage } from "../../features/localisation/useLanguage";
import { User } from "../../features/user/user";
import { useAgents } from "../../features/agents/useAgents";
import { useUser } from "../../features/user/useUser";
import { DepositWithdrawMoneyModal } from "./deposit&withdraw/DepositWithdrawMoney";
import { alpha } from '@mui/system';
import { theme } from "../../themes/golden_dark/theme";
import { Link } from "react-router-dom";
import { CreatePlayerAgentModal } from "./players_&_agents_creation/CreatePlayerAgent";
import { PercentInlineEditor } from "./percent_editor/PercentInlineEditor";
import { BanUser } from "../commons/ban_user/BanUser";

export const PlayersNAgentsTable: FC = () => {
    const { languagePack: {pack: {manageUsers: lang, playersAgents: playersAgentsLang, createUser: createUserLang}}} = useLanguage()
    const { updateQueryParameter } = useQuery()
    const { filter: { orderBy}, users, isShowUsersLoading } = usePlayersAgents()
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0)
    const [depositRecipient, setDepositRecipient] = useState<User | null>(null)
    const [withdrawSender, setWithdrawSender] = useState<User | null>(null)
    const { selectedAgent } = useAgents()
    const { user: loggedUser } = useUser()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [contextMenuUser, setContextMenuUser] = useState<User | null>(null)
    const [ editUser, setEditUser ] = useState<User | null>(null)
    const [editedPercentUserID, setEditedPercentUserID] = useState<number | null>(null)

    const updateHeight = useCallback(() => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight
            if (height !== newHeight) {
                setHeight(newHeight)
            }
        }
    }, [height])

    useEffect(() => {
        updateHeight()
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [updateHeight]);

    const handleClickSorting = (sort: SortingPlayerAgent) => {
        updateQueryParameter('orderBy', sort)
    }

    const handleMenuOpen = (user: User) => (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setContextMenuUser(user)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setContextMenuUser(null)
    };

    return <Box
        height={'100%'}
        ref={wrapperRef}
        flexGrow={1}
    >
        <TableContainer sx={{ maxHeight: `${height}px` }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <SortedCellPlayersAgents orderName={SortingPlayerAgent.Username} currentOrderBy={orderBy}
                                                     onClick={handleClickSorting}>
                                {lang.usernameHeader}
                            </SortedCellPlayersAgents>
                        </TableCell>
                        <TableCell>
                            <SortedCellPlayersAgents orderName={SortingPlayerAgent.Balance} currentOrderBy={orderBy}
                                                     onClick={handleClickSorting}>
                                {lang.balanceTableHeader}
                            </SortedCellPlayersAgents>
                        </TableCell>
                        <TableCell align={'center'}>
                            {lang.operationsTableHeader}
                        </TableCell>
                        <TableCell>
                            {lang.dateTableHeader}
                        </TableCell>
                        <TableCell>
                            {lang.commissionTableHeader}
                        </TableCell>
                        <TableCell>
                            {lang.actionsTableHeader}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { users !== null && users.map(user => {
                        const isParent = loggedUser?.user_id === user.user_id || selectedAgent?.user_id === user.user_id

                        return <TableRow key={user.user_id}
                                  sx={{ backgroundColor: (loggedUser?.user_id === user.user_id || selectedAgent?.user_id === user.user_id) ? alpha(theme.palette.primary.main, 0.4) : 'initial' }}>
                            <TableCell>
                                <Stack alignItems={'center'} rowGap={'4px'}>
                                    {user.user_name}
                                    <UserRoleChip userRole={user.user_role}/>
                                </Stack>

                            </TableCell>
                            <TableCell>{convertCentsToCurrencyString(user.user_balance, true)}</TableCell>
                            <TableCell>
                                <Stack direction={'row'}>
                                    <Button
                                        disabled={isParent}
                                        variant={'contained'}
                                        onClick={() => setDepositRecipient(user)}
                                    >
                                        {playersAgentsLang.depositBtn}
                                    </Button>
                                    <Button
                                        disabled={isParent}
                                        onClick={() => setWithdrawSender(user)}
                                    >
                                        {playersAgentsLang.withdrawBtn}
                                    </Button>
                                </Stack>
                            </TableCell>
                            <TableCell>{user.registration_time?.split(' ')[0]}</TableCell>
                            <TableCell width={'180px'}>
                                {user.user_role === 'agent' &&
                                    <PercentInlineEditor
                                        userId={user.user_id}
                                        disabled={editedPercentUserID !== user.user_id && editedPercentUserID !== null}
                                        value={user.deposit_rate}
                                        onDone={() => {
                                            setEditedPercentUserID(null)
                                        }}
                                        onEnterEditMode={() => {
                                            setEditedPercentUserID(user.user_id)
                                        }}/>}
                            </TableCell>
                            <TableCell align={'right'}>
                                <IconButton disabled={loggedUser?.user_id === user.user_id} onClick={handleMenuOpen(user)}>
                                    <MoreVertIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    })}

                    { users === null && isShowUsersLoading && <TableRow>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                        <TableCell><Skeleton animation="wave"/></TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
        </TableContainer>
        { users !== null && users.length === 0 &&
            <Alert variant="outlined" severity="warning">
                {playersAgentsLang.noUsersFoundMessage}
            </Alert>}

        { depositRecipient && <DepositWithdrawMoneyModal mode={'deposit'} initiator={depositRecipient} close={() => setDepositRecipient(null)}/> }
        { withdrawSender && <DepositWithdrawMoneyModal mode={'withdraw'} initiator={withdrawSender} close={() => setWithdrawSender(null)}/> }
        { editUser && <CreatePlayerAgentModal dialogTitle={editUser.user_role === "agent" ? createUserLang.agentEditingTitle : createUserLang.playerEditingTitle} role={editUser.user_role === "agent" ? "agent" : "player"} close={() => setEditUser(null)} userIdForEdit={editUser.user_id} /> }


        <Menu
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem disabled={contextMenuUser?.user_role !== 'player'}><Link to={`/game_history?username=${contextMenuUser?.user_name}`}>{playersAgentsLang.gameHistoryBTN}</Link></MenuItem>
            {contextMenuUser !== null && loggedUser?.user_id !== contextMenuUser.user_id && <MenuItem onClick={() => {
                setEditUser(contextMenuUser)
                handleMenuClose()
            }}>{playersAgentsLang.editUserBTN}</MenuItem>}
            {contextMenuUser !== null && <MenuItem>
                <BanUser user={contextMenuUser}/>
            </MenuItem>}
        </Menu>
    </Box>
}