import React, { FC } from "react";
import { useNetwinRoyaltyStatisticsWatcher } from "../features/netwin/useNetwinRoyaltyStatisticsWatcher";
import { useNetwin } from "../features/netwin/useNetwin";
import { NetwinRoyaltyStatisticsTable } from "../components/netwin/NetwinRoyaltyStatisticsTable";
import { NetwinAccess } from "../features/netwin/models/access";
import { ContentPage } from "../components/layout/content_components/ContentPage";
import { FilterPanel, FilterPanelSide } from "../components/layout/content_components/FilterPanel";
import { MainContentZone } from "../components/layout/content_components/MainContentZone";
import { ContentLoader } from "../components/layout/content_components/ContentLoader";
import {
    NetwinRoyaltyStatisticsList
} from "../components/netwin/netwin_royalry_statistics_list/NetwinRoyaltyStatisticsList";
import { useCommonMediaQueries } from "../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { NetwinRoyaltySort } from "../features/netwin/models/sorting";
import { useLanguage } from "../features/localisation/useLanguage";
import { Box, Stack } from "@mui/material";
import { GeneralInfoCard } from "../components/commons/GeneralInfoCard";
import { convertCentsToCurrencyString } from "../features/common_funcs";
import {UsernameFilter} from "../components/commons/username_filter_v2/UsernameFilter";
import {TimeFilter} from "../components-v2/commons/time_filter/TimeFilter";

export const NetwinGeneralInfoPage: FC = () => {
    const { languagePack: {pack: { commonSortingNames: lanSorting, netwinRoyaltyStatisticsTable: lang, netwinUsersTable: langUsers}}} = useLanguage()
    useNetwinRoyaltyStatisticsWatcher()
    const { royaltyFilter, isNetwinRoyaltyLoading, royaltySummary, selectedAgentCommission } = useNetwin()
    const { smallScreen } = useCommonMediaQueries()

    const sortingList = [
        { title: lanSorting.providerAsc, orderBy: NetwinRoyaltySort.provider, sortDirection: "ASC" },
        { title: lanSorting.providerDesc, orderBy: NetwinRoyaltySort.provider, sortDirection: "DESC" },
        { title: lanSorting.betAsc, orderBy: NetwinRoyaltySort.user_losses, sortDirection: "ASC" },
        { title: lanSorting.betDesc, orderBy: NetwinRoyaltySort.user_losses, sortDirection: "DESC" },
        { title: lanSorting.winAsc, orderBy: NetwinRoyaltySort.user_wins, sortDirection: "ASC" },
        { title: lanSorting.winDesc, orderBy: NetwinRoyaltySort.user_wins, sortDirection: "DESC" },
        { title: lanSorting.netwinAsc, orderBy: NetwinRoyaltySort.ggr, sortDirection: "ASC" },
        { title: lanSorting.netwinDesc, orderBy: NetwinRoyaltySort.ggr, sortDirection: "DESC" },
    ]

    return <ContentPage accessUserRoles={NetwinAccess}>
        <FilterPanel
            currentSortDirection={royaltyFilter.sortDirection ?? undefined}
            currentOrderBy={royaltyFilter.orderBy ?? undefined}
            sortingList={sortingList}
        >
            <FilterPanelSide>
                <UsernameFilter username={royaltyFilter.username} pullParamsToAddressBar/>
                <TimeFilter
                    fromTime={royaltyFilter.fromTime}
                    toTime={royaltyFilter.toTime}
                    pullParamsToAddressBar/>
            </FilterPanelSide>
        </FilterPanel>
        <MainContentZone withAgentsTree>
            <ContentLoader isLoading={isNetwinRoyaltyLoading}/>
            {!smallScreen && <Stack direction={'row'} padding={'8px'} gap={'8px'}>
                <Box flex={1}>
                    <GeneralInfoCard title={lang.betsHeader}
                                     value={royaltySummary.totalBets ? convertCentsToCurrencyString(royaltySummary.totalBets, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
                <Box flex={1}>
                    <GeneralInfoCard title={lang.winsHeader}
                                     value={royaltySummary.totalWins ? convertCentsToCurrencyString(royaltySummary.totalWins, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
                <Box flex={1}>
                    <GeneralInfoCard title={lang.netwinHeader}
                                     value={royaltySummary.totalGGR ? convertCentsToCurrencyString(royaltySummary.totalGGR, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
                <Box flex={1}>
                    <GeneralInfoCard title={`${langUsers.commissionHeader}${selectedAgentCommission !== null ? ` (${selectedAgentCommission * 100}%)` : ''}`}
                                     value={royaltySummary.totalCommission ? convertCentsToCurrencyString(royaltySummary.totalCommission, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
            </Stack>}
            {smallScreen ? <NetwinRoyaltyStatisticsList/> : <NetwinRoyaltyStatisticsTable/>}
        </MainContentZone>
    </ContentPage>
}