import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { theme } from "../../../themes/golden_dark/theme";
import { alpha } from "@mui/system";

interface SideMenuItemProps {
    active?: boolean
    title: string
    link?: string
    icon?: ReactNode
    onClick?: () => void
}

export const SideMenuItem: FC<SideMenuItemProps> = ({active= false, title, link, icon, onClick= () => {}}) => {
    return <Stack
        direction={'row'}
        columnGap={'10px'}
        py={'8px'}
        px={'24px'}
        borderRadius={active ? '0 20px 20px 0' : undefined}
        color={active ? alpha(theme.palette.primary.contrastText, 0.8) : theme.palette.primary.main}
        sx={{
            background: active ? `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)` : undefined,
            boxShadow: active ? `2px 2px 4px ${alpha(theme.palette.primary.dark, 0.33)}` : undefined
        }}
    >
        {icon && icon}
        {link ? <Link to={link}><Typography onClick={onClick} fontWeight={500}
                                                             fontFamily={'Poppins'}>{title}</Typography></Link> :
            <Typography style={{cursor: "pointer"}} fontWeight={500} fontFamily={'Poppins'} onClick={onClick}>{title}</Typography>}
    </Stack>
}