import React, { FC, useState } from "react";
import { User } from "../../../features/user/user";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { LinearProgress, Stack, Switch, Typography } from "@mui/material";
import { usePlayersAgents } from "../../../features/manage_players_agents/usePlayersAgents";

interface BanUserProps {
    user: User
}

export const BanUser: FC<BanUserProps> = ({user}) => {
    const { languagePack: { pack: {userInfo: lang} }} = useLanguage()
    const [localBanState, setLocalBanState] = useState<boolean>(user.banned)
    // const [ banIntent, setBanIntent ] = useState<BanUserSending|null>(null)
    const [isSending, setIsSending] = useState<boolean>(false)
    const { banUser } = usePlayersAgents()

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalBanState(event.target.checked)
        banUser(user.user_id, event.target.checked, setIsSending, setLocalBanState)
    }

    return <Stack direction={'row'} gap={'10px'} alignItems={'center'} color={'var(--dark-color)'}>
        <Switch checked={localBanState} color={'error'} onChange={onChangeHandler} disabled={isSending}/>
        <Stack>
            <Typography lineHeight={1}>{lang.banUserLabel}</Typography>
            <Typography lineHeight={1} fontSize={'10px'} fontWeight={300} color={user.banned ? '#fa023f' : '#38c700'}>
                {isSending ? <LinearProgress /> : (user.banned ? lang.banUserStateBanned : lang.banUserStateActive)}
            </Typography>
        </Stack>
    </Stack>
}