import React, {FC, useEffect} from "react";
import {Box, FormControlLabel, Stack, Switch, Tabs} from "@mui/material";
import { useUserTransfersWatcher } from "../features/user_transfers/useUserTransfersWatcher";
import { useLanguage } from "../features/localisation/useLanguage";
import Tab from "@mui/material/Tab";
import { useQuery } from "../features/common_funcs/query_hook/useQuery";
import { useUserTransfers } from "../features/user_transfers/useUserTransfers";
import { UserTransfersTable } from "../components/user_transfers/UserTransfersTable";
import { TransactionTypeFilter } from "../components/commons/transaction_type_filter/TransactionTypeFilter";
import { UserTransfersAccess } from "../features/user_transfers/models/access";
import { ContentPage } from "../components/layout/content_components/ContentPage";
import { FilterPanel, FilterPanelSide } from "../components/layout/content_components/FilterPanel";
import { MainContentZone } from "../components/layout/content_components/MainContentZone";
import { PaginatorRow } from "../components/layout/content_components/PaginatorRow";
import { ContentLoader } from "../components/layout/content_components/ContentLoader";
import { useCommonMediaQueries } from "../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { UserRoleFilter } from "../components/commons/user_role_filter/UserRoleFilter";
import { UserTransfersList } from "../components/user_transfers/user_transfers_list/UserTransfersList";
import {UsernameFilter} from "../components/commons/username_filter_v2/UsernameFilter";
import {GeneralInfoCard} from "../components/commons/GeneralInfoCard";
import {convertCentsToCurrencyString} from "../features/common_funcs";
import {TimeFilter} from "../components-v2/commons/time_filter/TimeFilter";
import {useAppDispatch} from "../store";
import {resetUserTransfers} from "../features/user_transfers/UserTransfersSlice";

export const UserTransfersPage: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: {pack: { playersAgents: playersAgentsLang}}} = useLanguage()
    useUserTransfersWatcher()
    const { smallScreen, xxSmallScreen } = useCommonMediaQueries()
    const { filter, isUserTransfersLoading, totalPages, pageSize, currentPage, usersTransfersInfo } = useUserTransfers()

    const { updateQueryParameter } = useQuery()

    const handleTabsChange = (event: React.SyntheticEvent, newValue: string | null) => {
        updateQueryParameter("userRole", newValue)
        updateQueryParameter("page", "1")
    }

    useEffect(() => {
        return () => {
            dispatch(resetUserTransfers())
        }
    }, [dispatch]);

    return <ContentPage accessUserRoles={UserTransfersAccess}>
        <FilterPanel>
            <FilterPanelSide>
                <UsernameFilter username={filter.username} pullParamsToAddressBar/>
                {xxSmallScreen && <UserRoleFilter userRole={filter.userRole} pullParamsToAddressBar/>}
                <TimeFilter
                    fromTime={filter.fromTime}
                    toTime={filter.toTime}
                    pullParamsToAddressBar/>
                <TransactionTypeFilter typeTransaction={filter.typeTransaction} pullParamsToAddressBar/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={filter.direct ?? false}
                            onChange={(event) => {
                                updateQueryParameter("direct", event.target.checked ? "true" : "false")
                            }}
                        />
                    }
                    label={playersAgentsLang.directSwitchLabel}
                    labelPlacement="start"
                    sx={{ whiteSpace: 'nowrap' }}
                />
            </FilterPanelSide>
        </FilterPanel>
        <MainContentZone withAgentsTree>
            {!xxSmallScreen && <Tabs
                value={filter.userRole}
                onChange={handleTabsChange}
            >
                <Tab label={playersAgentsLang.allUsersTab} value={null}/>
                <Tab label={playersAgentsLang.playersTab} value={"player"}/>
                <Tab label={playersAgentsLang.agentsTab} value={"agent"}/>
            </Tabs>}
            <ContentLoader isLoading={isUserTransfersLoading}/>
            {!smallScreen && <Stack direction={'row'} padding={'8px'} gap={'8px'}>
                <Box flex={1}>
                    <GeneralInfoCard title={playersAgentsLang.totalDeposits}
                                     value={usersTransfersInfo.totalDeposits !== null ? convertCentsToCurrencyString(usersTransfersInfo.totalDeposits, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
                <Box flex={1}>
                    <GeneralInfoCard title={playersAgentsLang.totalWithdrawals}
                                     value={usersTransfersInfo.totalWithdraws !== null ? convertCentsToCurrencyString(usersTransfersInfo.totalWithdraws, true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
                <Box flex={1}>
                    <GeneralInfoCard title={playersAgentsLang.netDeposit}
                                     value={usersTransfersInfo.totalDeposits !== null && usersTransfersInfo.totalWithdraws !== null ? convertCentsToCurrencyString((usersTransfersInfo.totalDeposits - usersTransfersInfo.totalWithdraws), true) : undefined}
                                     suffix={'ARS'}/>
                </Box>
            </Stack>}
            {smallScreen ? <UserTransfersList/> : <UserTransfersTable/>}
            {!xxSmallScreen &&
                <PaginatorRow totalPages={totalPages} currentPage={currentPage} pageSize={pageSize}/>
            }
        </MainContentZone>
    </ContentPage>

}