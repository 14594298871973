import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const deLang: LangPack = {
    lang: Lang.DE,
    langName: "German",
    langShort: "de",
    pack: {
        common: {
            yesBTN: "Ja",
            noBTN: "Nein",
            closeBTN: "Schließen",
            addBTN: "Hinzufügen",
            editBTN: "Bearbeiten",
            cancelBTN: "Abbrechen",
            applyBTN: "Anwenden",
            resetBTN: "Zurücksetzen",
            saveBTN: "Speichern",
            errorCodeTitle: "Fehlercode",
            optionsButton: 'Optionen',
            pageSizeSwitcherLabel: "Zeilen pro Seite",
            logOutConfirmationMessage: "Sind Sie sicher, dass Sie sich abmelden möchten?",
            youMustBeLoggedMessage: "Um diesen Abschnitt anzuzeigen, müssen Sie sich anmelden.",
            notEnoughRightsMessage: 'Nicht genug Rechte, um diesen Abschnitt anzuzeigen.'

        },
        login: {
            logInBTN: 'Anmelden',
            usernameLabel: 'Benutzername',
            passwordLabel: 'Passwort',
        },
        internalErrorMessages: {
            networkError: "Ein Netzwerkfehler ist aufgetreten, während die Anfrage ausgeführt wurde.",
            error502: "Ein Serverfehler 502 ist bei der Ausführung der Anfrage aufgetreten."
        },
        errorMessages: {
            2: "Keine Einträge vorhanden",
            3: "Die E-Mail-Adresse des Benutzers existiert bereits",
            4: "Die Telefonnummer des Benutzers existiert bereits",
            5: "Der Benutzer ist bereits angemeldet",
            6: "Falsches Passwort",
            7: "Leeres Ergebnis für Spiele",
            8: "Die ID des Ergebnisses stimmt nicht mit der ID der Anfrage überein",
            9: "Fehler bei der Anfrage des Ergebnisses",
            10: "Die Sitzungs-ID fehlt in der Antwort",
            11: "Der Benutzer existiert bereits",
            12: "Token ungültig. Bitte melden Sie sich erneut an.",
            13: "Sie haben nicht genügend Berechtigungen, um diese Aktion auszuführen",
            14: "Es fehlt die ID des untergeordneten Benutzers für diese Aktion",
            15: "Es fehlt der Geldbetrag",
            16: "Aktion existiert nicht",
            17: "Es fehlt der Token",
            18: "Nicht genügend Guthaben",
            19: "Sie müssen Elternteil dieses Benutzers sein, um diese Aktion auszuführen",
            20: "Benutzer existiert nicht",
            21: "Ungültiger Werttyp für dieses Feld",
            22: "Es fehlt das Passwort",
            23: "Es fehlt der Benutzername",
            24: "Es fehlt die Spiel-ID",
            25: "Es fehlt das neue Passwort",
            26: "Token konnte nicht festgelegt werden",
            27: "Token konnte nicht gelöscht werden",
            28: "Es fehlt die Benutzer-ID",
            29: "Ungültiges Datum- und Uhrzeitformat",
            30: "Aktualisierung der Benutzersperre fehlgeschlagen",
            31: "Dieser Benutzer muss in Ihrem Baum sein, um diese Aktion auszuführen",
            32: "Dieser Benutzer ist gesperrt und kann keine Aktionen durchführen",
            33: "Es fehlt die Währung",
            34: "Es fehlt der maximale Wert",
            35: "Der Limittyp muss Einzel-, Tages-, Wochen- oder Monatslimit sein",
            36: "Es fehlt der Limittyp",
            37: "Anbieter existiert nicht",
            38: "Es fehlt der Betrag des Level-Bedingungs",
            39: "Es fehlt der Typ der Level-Bedingung",
            40: "Es fehlt der Titel des Levels",
            41: "Cashback für dieses Level existiert bereits",
            42: "Kein Cashback für dieses Level vorhanden",
            43: "Bonus für diesen Promo-Code existiert bereits",
            44: "Bonus existiert nicht",
            45: "Es fehlt der Promo-Code",
            46: "Es fehlt der Startzeitpunkt",
            47: "Es fehlt der Betrag der Bedingung für die Einzahlung",
            48: "Es fehlt die Quote",
            49: "Es fehlt die Laufzeit in Tagen",
            50: "Es fehlt die ID",
            51: "Es fehlt der Cashback-Typ, sollte 'Einsatz' oder 'Einzahlung' sein",
            52: "Es fehlt der Cashback-Einsatz",
            53: "Diese Währung ist nicht verfügbar",
            54: "Es fehlt der Sperrparameter - true oder false",
            55: "Die Bankengruppe für diese Währung ist nicht zulässig",
            56: "Es fehlt der Name der Zahlungsmethode",
            57: "Für diese Zahlungsmethode fehlen obligatorische Parameter",
            58: "Diese Zahlungsmethode existiert nicht",
            59: "Diese Zahlungsmethode unterstützt nicht die Währung dieses Benutzers",
            60: "Dieser Benutzer hat kein vorhandenes Konto für dieses Zahlungssystem",
            61: "Der Elternteil dieses Benutzers hat kein vorhandenes Konto für dieses Zahlungssystem",
            62: "Es fehlt die Transfer-ID",
            63: "Transfer mit dieser ID existiert nicht",
            64: "Dieser Transfer wurde von einem anderen Elternteil angefordert",
            65: "Sie haben immer noch einen Bonus, der nicht abgelaufen ist",
            66: "Diese Bonusaktion hat noch nicht begonnen", // this bonus promo action has not started yet
            67: "Diese Bonusaktion ist bereits abgelaufen", // this bonus promo action is already over
            68: "Die Einzahlung entspricht nicht den Bonusanforderungen", // this deposit does not fit the bonus requirements
            69: "Diese Überweisung ist nicht ausstehend und kann nicht genehmigt oder abgelehnt werden", // this transfer is not pending and cannot be approved or declined
            70: "Das Genehmigungsflag (true/false) fehlt", // approve flag (true/false) missing
            71: "Falsche Wochentage für diesen Bonus", // wrong week day for this bonus
            72: "Die Anzahl Ihrer Einzahlungen entspricht nicht den Bonusanforderungen", // the number of your deposits does not fit the bonus requirements
            73: "Zwingende Parameter für diese Zahlungsmethode fehlen", // mandatory parameters for this payment method missing
            74: "Die Einzahlungsrate fehlt", // deposit rate missing
            75: "Die Benutzerrolle fehlt", // user role missing
            76: "Sie können keinen Benutzer mit dieser Benutzerrolle erstellen", // you cannot create a user with this user role
            77: "Das Flag 'Funktion aktiv' fehlt", // fun active parameter missing
            78: "Diese Funktion ist für diese Webseite nicht verfügbar", // this function is not available for this website
            79: "Die Menge der Münzen fehlt", // coins amount missing
            80: "Der Preis für das Münzenpaket fehlt", // price for coins pack missing
            81: "Der Typ des Anbieters fehlt", // provider type is missing
            82: "Es ist ein Fehler bei der Ausführung aufgetreten", // outcome request error
            83: "Die Anbieter-ID fehlt", // provider id missing
            84: "Die Vertrags-ID fehlt", // contractor id missing
            85: "Der Vertragspartner existiert nicht" // contractor does not exist
        },
        sideMenu: {
            logOutBtn: "Abmelden",
            dashboard: 'Dashboard',
            users: 'Benutzer',
            gameHistory: 'Spielverlauf',
            moneyTransfers: 'Geldtransfers',
            limitsDivider: 'Limits',
            limitsGeneral: 'Allgemein',
            limitsOutcome: 'Outcome',
            bonus: 'Bonus',
            paymentSystems: 'Zahlungssysteme',
            buyBalance: 'Guthaben aufladen',
            affiliateSystem: 'Partnerprogramm',
            crmSettings: 'CRM-Einstellungen',
            triggerEvents: 'Auslösende Ereignisse',
            websiteSettings: 'Webseiteneinstellungen',
            securityLog: 'Sicherheitsprotokoll',
            royaltyStatistics: 'Royalty-Statistiken',
            finances: 'Finanzen',
            providers: 'Anbieter',
            netwinDivider: 'Netwin',
            netwinUser: 'Agenten',
            netwinGeneralInfo: 'Allgemeine Informationen',
            agentTransfers: 'Agenten-Transfers',
            userTransfers: 'Benutzer-Transfers',
            depositsAndWithdrawals: 'Einzahlungen und Auszahlungen',
            myFinances: 'Meine Finanzen'
        },
        commonSortingNames: {
            sortLabel: 'Sortierung',
            usernameAsc: '↑ Nach Namen',
            usernameDesc: '↓ Nach Namen',
            balanceAsc: '↑ Guthaben',
            balanceDesc: '↓ Guthaben',
            providerAsc: '↑ Provider',
            providerDesc: '↓ Provider',
            gameAsc: '↑ Spiel',
            gameDesc: '↓ Spiel',
            betAsc: '↑ Einsatz',
            betDesc: '↓ Einsatz',
            winAsc: '↑ Sieg',
            winDesc: '↓ Sieg',
            dateAsc: '↑ Datum',
            dateDesc: '↓ Datum',
            netwinAsc: '↑ Netwin',
            netwinDesc: '↓ Netwin',
            percentAsc: '↑ Prozentsatz',
            percentDesc: '↓ Prozentsatz'
        },
        userProfileForm: {
            userProfileTabLabel: 'Benutzerprofil',
            passwordTabLabel: 'Passwort',
            emailIsRequiredMess: 'E-Mail ist erforderlich',
            notValidEmail: 'Geben Sie eine gültige E-Mail-Adresse ein',
            currentPassFieldLabel: "Aktuelles Passwort",
            emailFieldLabel: "E-Mail",
            emptyFieldWarningMessage: "Dieses Feld muss ausgefüllt sein",
            firstnameFieldLabel: "Vorname",
            lastnameFieldLabel: "Nachname",
            newPassFieldLabel: "Neues Passwort",
            passNotMatchWarningMessage: "Neues Passwort und Passwortwiederholung stimmen nicht überein",
            phoneFieldLabel: "Telefon",
            retypePassFieldLabel: "Neues Passwort wiederholen",
            saveInfoBTN: "Informationen speichern",
            updatePassBTN: "Passwort aktualisieren",
            onlyRequiredFieldsLabel: "Nur erforderliche Felder",
        },
        userInfo: {
            registeredLabel: 'Registriert',
            roleLabel: 'Rolle',
            balanceLabel: 'Guthaben',
            firstNameLabel: 'Vorname',
            lastnameLabel: 'Nachname',
            phoneLabel: 'Telefon',
            emailLabel: 'E-Mail',
            depositMoneyBtn: 'Geld einzahlen',
            withdrawMoneyBtn: 'Geld auszahlen',
            moneyTransfersBtn: 'Geldtransfers',
            gamesHistoryBtn: 'Spielverlauf',
            banUserLabel: 'Benutzer sperren',
            banUserStateBanned: 'Gesperrt',
            banUserStateActive: 'Aktiv'
        },
        createUser: {
            title: "Neuen Benutzer erstellen",
            usernameLabel: "Benutzername",
            createBtn: "Erstellen",
            agentCreationTitle: "Anbieter erstellen",
            playerCreationTitle: "Spieler erstellen",
            agentEditingTitle: "Edit agent",
            playerEditingTitle: "Edit player",
            agentSuccessfullyCreatedMessage: "Sie haben erfolgreich einen Anbieter erstellt.",
            playerSuccessfullyCreatedMessage: "Sie haben erfolgreich einen Spieler erstellt.",
            createAgentBTN: "Agent erstellen",
            createPlayerBTN: "Spieler erstellen",
            editAgentBTN: "Agent speichern",
            editPlayerBTN: "Spieler speichern",
            depositEnabledSwitcherLabel: 'Einzahlung aktivieren',
            withdrawEnabledSwitcherLabel: 'Auszahlung aktivieren',
            onlyPlayersSwitcherLabel: 'Nur Spieler',
        },
        moneyInput: {
            maxAmountMessage: "Maximaler Betrag",
            exceedAmountError: "Maximalbetrag überschritten.",
            moreThanOneDotError: "Sie haben zu viele Punkte eingegeben.",
            moreThanTwoDecimals: "Sie haben mehr als zwei Dezimalstellen eingegeben."
        },
        depositMoneyToUser: {
            depositLabel: "Einzahlung",
            confirmSendingMessage: "Sind Sie sicher, dass Sie Geld an den Benutzer %username% senden möchten?",
            moneySuccessfulSentMessage: "Sie haben erfolgreich %username% %money% gesendet.",
            moneySuccessfulSentMessageShort: "Sie haben erfolgreich Geld gesendet.",
            sendBtn: "Senden",
            senderLabel: "Absender",
            recipientLabel: "Empfänger",
        },
        withdrawMoneyFromUser: {
            withdrawLabel: "Geld aus dem System abheben",
            confirmWithdrawMessage: "Sind Sie sicher, dass Sie Geld aus dem System %username% abheben möchten?",
            moneySuccessfulWithdrawedMessage: "Sie haben erfolgreich %money% aus dem System %username% abgehoben.",
            moneySuccessfulWithdrawedMessageShort: "Sie haben erfolgreich Geld abgehoben.",
            withdrawBtn: "Abheben",
            withdrawFromLabel: "Von",
            toReceiverLabel: "An",
            withdrawAllBtn: "Alles abheben"
        },
        moneyTransfersTable: {
            usernameHeader: "Benutzername",
            amountTableHeader: "Betrag",
            methodTableHeader: "Methode",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Einzahlung/Auszahlung",
            dateTimeTableHeader: "Datum/Uhrzeit",
            moneyTransfersNotFoundMessage: "Geldtransfers nicht gefunden"
        },
        gamesHistory: {
            userNameTableHeader: 'Benutzername',
            providerTableHeader: 'Anbieter',
            gameTableHeader: 'Spiel',
            betAmountTableHeader: 'Einsatz',
            winAmountTableHeader: 'Gewinn',
            dateTimeTableHeader: 'Datum/Uhrzeit',
            gamesHistoryNotFoundMessage: 'Keine Aufzeichnungen im Spielverlauf gefunden'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Keine verfügbaren Zahlungsmethoden",
            paymentMethodsDropdownLabel: "Zahlungsmethoden",
            yourPaymentAccountsTitle: "Ihre Zahlungskonten",
            addPaymentMethodBTN: "Zahlungsmethode hinzufügen",
            paymentAccountSuccessfullyUpdated: "Sie haben Ihr Zahlungskonto erfolgreich aktualisiert.",
            depositRateLabel: 'Einzahlungsrate',
            depositRateSavedMessage: 'Die Einzahlungsrate wurde erfolgreich gespeichert.'
        },
        approveTransaction: {
            userRequestPayoutTitle: "Benutzer %username% hat eine Auszahlung von %money% über %payment_system% angefordert.",
            userRequestPaymentTitle: "Benutzer %username% hat eine Zahlung von %money% über %payment_system% zur Aufladung seines Kontos getätigt.",
            confirmPayoutMessage: "Bestätigen Sie die Transaktion?",
            declineBTN: "Ablehnen",
            approveBTN: "Bestätigen"
        },
        requestPayment: {
            choosePaymentAccountTitle: "Wählen Sie das Zahlungskonto aus\n für die Aufladung des Kontos",
            paymentAmountLabel: "Zahlungsbetrag",
            youllReceiveOnBalanceLabel: "Sie erhalten auf dem Konto",
            depositRateHelper: "Einzahlungsrate %rate%",
            backBtn: "Zurück",
            nextBtn: "Weiter",
            iTransferMoneyBtn: "Ich überweise Geld",
            transferMoneyToThisAccountMessage: "1. Überweisen Sie Geld auf dieses Konto",
            enterYourProofMessage: "2. Geben Sie Ihren Nachweis ein, um Ihre Zahlung zu überprüfen"
        },
        securityLog: {
            logID: "ID",
            userName: "Benutzername",
            userIP: "Benutzer-IP",
            logAction: "Aktion",
            requestJSON: "Anfrage JSON",
            responseJSON: "Antwort JSON",
            timestamp: "Datum/Uhrzeit",
            securityLogNotFoundMessage: "Einträge im Sicherheitsprotokoll nicht gefunden"
        },
        royaltyStatistics: {
            royaltyStatisticsTitle: "Royalty-Statistiken",
            providerTableHeader: "Anbieter",
            sectionTableHeader: "Abschnitt",
            currencyTableHeader: "Währung",
            userLossesTableHeader: "Verluste des Benutzers",
            userWinsTableHeader: "Gewinne des Benutzers",
            ggrTableHeader: "GGR",
            royaltyStatisticsNotFoundMessage: "Einträge in den Royalty-Statistiken nicht gefunden"
        },
        manageUsers: {
            usersTreeSwitcher: "Benutzerbaum",
            createUserBtn: "Benutzer erstellen",
            activeTableHeader: "Aktiv",
            usernameHeader: "Benutzername",
            firstNameTableHeader: "Vorname",
            lastnameTableHeader: "Nachname",
            balanceTableHeader: "Guthaben",
            currencyTableHeader: "Währung",
            roleTableHeader: "Rolle",
            emailTableHeader: "E-Mail",
            phoneTableHeader: "Telefon",
            operationsTableHeader: "Operationen",
            dateTableHeader: "Datum",
            commissionTableHeader: "Kommission",
            actionsTableHeader: "Aktionen",
            usersNotFoundMessage: "Benutzer nicht gefunden"
        },
        timeFilter: {
            fromTimeDefaultLabel: "Von Zeitpunkt",
            toTimeDefaultLabel: "Bis Zeitpunkt",
            fromTimeLabel: "Von %time%",
            toTimeLabel: "Bis %time%",
            todayBTN: 'Heute',
            yesterdayBTN: 'Gestern',
            weekBTN: 'Woche',
            monthBTN: 'Monat',

            date: 'Datum',
            quickDates: {
                today: 'Heute',
                yesterday: 'Gestern',
                lastWeek: 'Letzte Woche',
                thisWeek: 'DieserKW',
                lastMonth: 'Letzter Monat',
                thisMonth: 'Dieser Monat',
            }
        },
        usernameFilter: {
            usernameFilterLabel: "Benutzername"
        },
        userRoles: {
            platform: "Plattform",
            owner: "Besitzer",
            superadmin: "Superadmin",
            admin: "Admin",
            cashier: "Kassierer",
            player: "Spieler",
            agent: "Agent",
        },
        currencySwitcher: {
            currencyLabel: 'Währung'
        },
        bonusConstructor: {
            createBonusBTN: 'Bonus erstellen',
            bonusConstructorTitle: 'Bonuskonstruktor',
            promoCodeLabel: 'Promo-Code',
            promoCodeHelper: 'Name des Promo-Codes, der bei der Bonusanfrage eingegeben wird',
            conditionDepositLabel: 'Bedingung Einzahlung',
            conditionDepositHelper: 'minimale Einzahlung, die für den Bonus erforderlich ist',
            wagerLabel: 'Wettanforderung',
            wagerHelper: 'Wettfaktor',
            daysValidLabel: 'Gültig für Tage',
            daysValidHelper: 'wie lange der Bonus nach der Anfrage gültig ist (in Tagen)',
            bonusAmountLabel: 'Bonusbetrag',
            bonusAmountHelper: 'Bonuspreis in Geld',
            startPromoDateLabel: 'Startdatum der Promoaktion',
            endPromoDateLabel: 'Enddatum der Promoaktion',
            additionalConditionLabel: "Zusätzliche Bedingung",
            additionalConditionHelper: 'zusätzliche Bedingung für den Erhalt des Bonus',
            bonusPercentageLabel: 'Bonusprozentsatz, %',
            bonusPercentageHelper: 'Bonuspreis in Prozent des Einzahlungsbetrags',
            freeSpinsLabel: 'Freispiele',
            freeSpinsHelper: 'Bonus-Freispiele',
            bonusMaxLabel: 'Maximaler Bonus',
            bonusMaxHelper: 'oberer Bonusgrenzwert',
            saveBonusBTN: "Bonus speichern",
            bonusCreateSuccessMessage: 'Bonus erfolgreich gespeichert!'
        },
        bonusList: {
            promoLabel: 'Promo-Code',
            fromTimeLabel: 'Beginn',
            toTimeLabel: 'Ende',
            conditionDepositLabel: 'Einzahlung',
            additionConditionLabel: 'Zusätzliche Bedingung',
            wagerLabel: 'Wettanforderung',
            bonusAmountLabel: 'Bonusbetrag',
            bonusPercentageLabel: 'Bonus in Prozent',
            bonusFreeSpinsLabel: 'Freispiele',
            bonusMaxLabel: 'Maximaler Bonus',
            daysValidLabel: 'Gültig für Tage',
            deleteBonusConfirmationMessage: 'Sind Sie sicher, dass Sie das Bonus löschen können?',
        },
        coinsPackCreator: {
            savePackBTN: 'Paket speichern',
            coinsPackCreatorTitle: 'Paketkonstruktor',
            coinsLabel: 'Coins',
            coinsHelper: 'Anzahl der Coins, die im Paket enthalten sind',
            priceLabel: 'Preis',
            priceHelper: 'Preis in Coins',
            freeCoinsLabel: 'Free Coins',
            freeCoinsHelper: 'Anzahl der Free Coins, die im Paket enthalten sind',
            coinsPackCreateSuccessMessage: 'Paket erfolgreich gespeichert!'
        },
        coinsList: {
            idHeader: 'ID', // coin pack ID
            coinsHeader: 'Coins', // coins
            priceHeader: 'Preis', // price
            freeCoinsHeader: 'Free Coins', // free coins
            deleteCoinsPackConfirmationMessage: 'Sind Sie sicher, dass Sie das Paket löschen können?', // 'Are you sure you want to delete this pack?'
            createCoinPackBTN: 'Paket erstellen' // 'Create coins pack'
        },
        playersAgents: {
            allUsersTab: "Alle Benutzer",
            playersTab: "Spieler",
            agentsTab: "Agenten",
            depositBtn: "Einzahlung",
            withdrawBtn: "Abheben",
            gameHistoryBTN: "Spielhistorie",
            editUserBTN: "Benutzer bearbeiten",
            editDepositRate: 'Einzahlungsrate',
            noUsersFoundMessage: "Keine Benutzer gefunden",
            userCardBalanceLabel: "Guthaben",
            totalDeposits: "Gesamteinzahlungen",
            totalWithdrawals: "Gesamtabhebungen",
            netDeposit: "Nettoeinzahlung",
            directSwitchLabel: 'Nur Direkt',
        },
        netwinUsersTable: {
            nameHeader: 'Name',
            betsHeader: 'Wetten',
            winsHeader: 'Gewinn',
            netwinHeader: 'Netwin',
            commissionHeader: 'Kommission',
            percentageHeader: 'Prozentsatz',
            noUsersFoundMessage: 'Keine Benutzer gefunden',
        },
        netwinRoyaltyStatisticsTable: {
            providerHeader: 'Anbieter',
            betsHeader: 'Wetten',
            winsHeader: 'Gewinn',
            netwinHeader: 'Netwin',
            royaltyStatisticsNotFoundMessage: 'Keine Statistiken gefunden',
        },
        agentTransfersTable: {
            typeHeader: "Typ",
            amountHeader: "Betrag",
            timeHeader: "Zeit",
            initiatorUserID: "Initiator-ID",
            userIdHeader: "Benutzer-ID",
            balanceHeader: "Guthaben",
            userBalanceBeforeHeader: "Guthaben vor dem Transfer",
            userBalanceAfterHeader: "Guthaben nach dem Transfer",
            originUserIdHeader: "Origin-Benutzer-ID",
            originUserBalanceBeforeHeader: "Ursprungssaldo vorher",
            originUserBalanceAfterHeader: "Ursprungssaldo danach",
            initiatorNameHeader: "Initiator",
            parentNameHeader: "Elternteil",
            userNameHeader: "Benutzer",
            transfersNotFoundMessage: "Keine Transaktionen gefunden",
            allTypeTab: "Alle Typen",
            depositsTab: "Einzahlungen",
            withdrawalsTab: "Auszahlungen",
        },
        balanceTooltip: {
            before: 'Vorher',
            after: 'Danach'
        },
        generalInfo: {
            myBalanceLabel: 'Mein Kontostand',
            financialDailyWidgetLabel: 'Finanzen',
            betsLabel: 'Wetten',
            userBetsLabel: 'Benutzerwetten',
            userLossesLabel: 'Benutzerverluste',
            userWinsLabel: 'Benutzergewinn',
            totalBetsLabel: 'Wetten',
            totalGGRLabel: 'GGR',
            totalNewUsersLabel: 'Neue Benutzer',
            totalActiveUsersLabel: 'Aktive Benutzer',
            usersDailyWidgetLabel: 'Aktivität',
            activeUsersLabel: 'Benutzer',
            newPlayersLabel: 'Neue Spieler',
            usersWidgetLabel: 'Benutzer',
            counterWidget: {
                revshare: 'Revshare',
                agents: 'Agenten',
                betshops: 'Betshops',
                cashiers: 'Kassierer',
                players: 'Spieler',
            }
        }
    }
}