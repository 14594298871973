import { Divider, Typography } from "@mui/material";
import { FC } from "react";
import { alpha } from "@mui/system";
import { theme } from "../../../themes/golden_dark/theme";

interface SideMenuDividerProps {
    children?: string
}

export const SideMenuDivider: FC<SideMenuDividerProps> = ({children}) => <Divider textAlign="left">{children && <Typography
    fontFamily={'Poppins'} variant={'caption'} color={alpha(theme.palette.primary.main, 0.6)}>
    {children}
</Typography>}</Divider>