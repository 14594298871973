import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const ruLang: LangPack = {
    lang: Lang.RU,
    langName: "Russian",
    langShort: "ru",
    pack: {
        common: {
            yesBTN: "Да",
            noBTN: "Нет",
            closeBTN: "Закрыть",
            addBTN: "Добавить",
            editBTN: "Редактировать",
            cancelBTN: "Отмена",
            applyBTN: "Применить",
            resetBTN: "Сбросить",
            saveBTN: "Сохранить",
            errorCodeTitle: "Код ошибки",
            optionsButton: 'Опции',
            pageSizeSwitcherLabel: "Строк на страницу",
            logOutConfirmationMessage: "Вы уверены, что хотите выйти?",
            youMustBeLoggedMessage: "Для просмотра этого раздела необходимо войти в систему.",
            notEnoughRightsMessage: 'Не достаточно прав для доступа к этой странице'
        },
        login: {
            logInBTN: 'Логин',
            usernameLabel: 'Никнейм',
            passwordLabel: 'Пароль',
        },
        internalErrorMessages: {
            networkError: "Произошла сетевая ошибка при выполнении запроса.",
            error502: "Произошла ошибка сервера 502 при выполнении запроса."
        },
        errorMessages: {
            2: "Нет записей",
            3: "Адрес электронной почты пользователя уже существует",
            4: "Номер телефона пользователя уже существует",
            5: "Пользователь уже вошел в систему",
            6: "Неверный пароль",
            7: "Пустой результат игр",
            8: "ID результата не совпадает с ID запроса",
            9: "Ошибка запроса результата",
            10: "ID сеанса отсутствует в ответе",
            11: "Пользователь уже существует",
            12: "Токен недействителен. Войдите повторно.",
            13: "У вас недостаточно прав для выполнения этого действия",
            14: "Отсутствует идентификатор дочернего пользователя для этого действия",
            15: "Отсутствует сумма денег",
            16: "Действие не существует",
            17: "Отсутствует токен",
            18: "Недостаточно средств",
            19: "Должны быть родителем этого пользователя для выполнения этого действия",
            20: "Пользователь не существует",
            21: "Неверный тип значения для этого поля",
            22: "Отсутствует пароль",
            23: "Отсутствует имя пользователя",
            24: "Отсутствует идентификатор игры",
            25: "Отсутствует новый пароль",
            26: "Не удалось установить токен",
            27: "Не удалось удалить токен",
            28: "Отсутствует идентификатор пользователя",
            29: "Неверный формат даты и времени",
            30: "Не удалось обновить блокировку пользователя",
            31: "Этот пользователь должен быть в вашем дереве для выполнения этого действия",
            32: "Этот пользователь заблокирован и не может выполнять никаких действий",
            33: "Отсутствует валюта",
            34: "Отсутствует максимальное значение",
            35: "Тип лимита должен быть единичным, ежедневным, еженедельным или ежемесячным",
            36: "Отсутствует тип лимита",
            37: "Поставщик не существует",
            38: "Отсутствует сумма условия уровня",
            39: "Отсутствует тип условия уровня",
            40: "Отсутствует заголовок уровня",
            41: "Cashback для этого уровня уже существует",
            42: "Нет cashback для этого уровня",
            43: "Бонус для этого промокода уже существует",
            44: "Бонус не существует",
            45: "Отсутствует промокод",
            46: "Отсутствует время начала",
            47: "Отсутствует сумма условия для депозита",
            48: "Отсутствует ставка",
            49: "Отсутствует срок действия в днях",
            50: "Отсутствует идентификатор",
            51: "Отсутствует тип cashback, должен быть 'ставка' или 'депозит'",
            52: "Отсутствует ставка cashback",
            53: "Эта валюта недоступна",
            54: "Отсутствует параметр блокировки - true или false",
            55: "Группа банка для этой валюты не является допустимой",
            56: "Отсутствует название способа оплаты",
            57: "Отсутствуют обязательные параметры для этого способа оплаты",
            58: "Этот способ оплаты не существует",
            59: "Этот способ оплаты не поддерживает валюту этого пользователя",
            60: "У этого пользователя нет существующего счета для этой системы оплаты",
            61: "Родитель этого пользователя не имеет существующего счета для этой системы оплаты",
            62: "Отсутствует идентификатор трансфера",
            63: "Трансфер с этим идентификатором не существует",
            64: "Этот трансфер запрошен другим родителем",
            65: "У вас все еще есть бонус, который не истек",
            66: "Эта акция ещё не началась", // this bonus promo action has not started
            67: "Эта акция уже завершена", // this bonus promo action is already over
            68: "Ваша сумма депозита не соответствует требованиям", // this deposit does not fit the bonus requirements
            69: "Этот трансфер не в состоянии 'ожидает подтверждения' и не может быть одобрен или отклонен", // this transfer is not pending and cannot be approved or declined
            70: "Отсутствует флаг 'одобрено' (true/false)", // approve flag (true/false) missing
            71: "Неверный день недели для этого бонуса", // wrong week day for this bonus
            72: "Количество депозитов не соответствует требованиям бонуса", // the number of your deposits does not fit the bonus requirements
            73: "Отсутствуют обязательные параметры для этого способа оплаты", // mandatory parameters for this payment method missing
            74: "Отсутствует ставка депозита", // deposit rate missing
            75: "Отсутствует роль пользователя", // user role missing
            76: "Вы не можете создать пользователя с этой ролью пользователя", // you cannot create a user with this user role
            77: "Отсутствует параметр активности", // fun active parameter missing
            78: "Эта функция недоступна для этого сайта", // this function is not available for this site
            79: "Отсутствует количество монет", // coins amount missing
            80: "Отсутствует цена для пакета монет", // price for coins pack missing
            81: "Отсутствует тип провайдера", // provider type is missing
            82: "Ошибка запроса outcome", // outcome request error
            83: "Отсутствует ID провайдера", // provider id missing
            84: "Отсутствует ID поставщика", // contractor id missing
            85: "Поставщик не существует" // contractor does not exist
        },
        sideMenu: {
            logOutBtn: "Выйти",
            dashboard: 'Панель управления',
            users: 'Пользователи',
            gameHistory: 'История игр',
            moneyTransfers: 'Денежные переводы',
            limitsDivider: 'Лимиты',
            limitsGeneral: 'Общие',
            limitsOutcome: 'Outcome',
            bonus: 'Бонус',
            paymentSystems: 'Мои платежи',
            buyBalance: 'Пополнить баланс',
            affiliateSystem: 'Партнерская система',
            crmSettings: 'Настройки CRM',
            triggerEvents: 'Триггерные события',
            websiteSettings: 'Настройки веб-сайта',
            securityLog: 'Журнал безопасности',
            royaltyStatistics: 'Статистика роялти',
            finances: 'Финансы',
            providers: 'Провайдеры',
            netwinDivider: 'Netwin',
            netwinUser: 'Агенты',
            netwinGeneralInfo: 'Общая информация',
            agentTransfers: 'Переводы агентов',
            userTransfers: 'Переводы пользователей',
            depositsAndWithdrawals: 'Депозиты и выводы',
            myFinances: 'Мои финансы'
        },
        commonSortingNames: {
            sortLabel: 'Сортировка',
            usernameAsc: '↑ По имени',
            usernameDesc: '↓ По имени',
            balanceAsc: '↑ Баланс',
            balanceDesc: '↓ Баланс',
            providerAsc: '↑ Провайдер',
            providerDesc: '↓ Провайдер',
            gameAsc: '↑ Игра',
            gameDesc: '↓ Игра',
            betAsc: '↑ Ставка',
            betDesc: '↓ Ставка',
            winAsc: '↑ Выигрыш',
            winDesc: '↓ Выигрыш',
            dateAsc: '↑ Дата',
            dateDesc: '↓ Дата',
            netwinAsc: '↑ Netwin',
            netwinDesc: '↓ Netwin',
            percentAsc: '↑ Процент',
            percentDesc: '↓ Процент',
        },
        userProfileForm: {
            userProfileTabLabel: 'Профиль пользователя',
            passwordTabLabel: 'Пароль',
            emailIsRequiredMess: 'Email обязателен',
            notValidEmail: 'Введите действительный адрес электронной почты',
            currentPassFieldLabel: "Текущий пароль",
            emailFieldLabel: "Email",
            emptyFieldWarningMessage: "Это поле должно быть заполнено",
            firstnameFieldLabel: "Имя",
            lastnameFieldLabel: "Фамилия",
            newPassFieldLabel: "Новый пароль",
            passNotMatchWarningMessage: "Новый пароль и повтор пароля не совпадают",
            phoneFieldLabel: "Телефон",
            retypePassFieldLabel: "Повторите новый пароль",
            saveInfoBTN: "Сохранить информацию",
            updatePassBTN: "Обновить пароль",
            onlyRequiredFieldsLabel: "Только обязательные поля",
        },
        userInfo: {
            registeredLabel: 'Зарегистрирован',
            roleLabel: 'Роль',
            balanceLabel: 'Баланс',
            firstNameLabel: 'Имя',
            lastnameLabel: 'Фамилия',
            phoneLabel: 'Телефон',
            emailLabel: 'Email',
            depositMoneyBtn: 'Внести деньги пользователю',
            withdrawMoneyBtn: 'Вывести деньги из системы',
            moneyTransfersBtn: 'Денежные переводы',
            gamesHistoryBtn: 'История игр',
            banUserLabel: 'Заблокировать пользователя',
            banUserStateBanned: 'Заблокирован',
            banUserStateActive: 'Активен'
        },
        createUser: {
            title: "Создать нового пользователя",
            usernameLabel: "Никнейм",
            createBtn: "Создать",
            agentCreationTitle: "Создание агента",
            playerCreationTitle: "Создание игрока",
            agentEditingTitle: "Редактировать агента",
            playerEditingTitle: "Редактировать игрока",
            agentSuccessfullyCreatedMessage: "Вы успешно создали агента.",
            playerSuccessfullyCreatedMessage: "Вы успешно создали игрока.",
            createAgentBTN: "Создать агента",
            createPlayerBTN: "Создать игрока",
            editAgentBTN: "Сохранить агента",
            editPlayerBTN: "Сохранить игрока",
            depositEnabledSwitcherLabel: 'Вкл. депозит',
            withdrawEnabledSwitcherLabel: 'Вкл. вывод',
            onlyPlayersSwitcherLabel: 'Только игроки',
        },
        moneyInput: {
            maxAmountMessage: "Максимальное значение",
            exceedAmountError: "Превышено максимальное значение.",
            moreThanOneDotError: "Вы ввели лишнюю точку.",
            moreThanTwoDecimals: "Вы ввели более двух десятичных знаков."
        },
        depositMoneyToUser: {
            depositLabel: "Депозит",
            confirmSendingMessage: "Вы уверены, что хотите отправить деньги пользователю %username%?",
            moneySuccessfulSentMessage: "Вы успешно отправили %username% %money%.",
            moneySuccessfulSentMessageShort: "Вы успешно отправили деньги.",
            sendBtn: "Отправить",
            senderLabel: "Отправитель",
            recipientLabel: "Получатель",
        },
        withdrawMoneyFromUser: {
            withdrawLabel: "Вывести деньги из системы",
            confirmWithdrawMessage: "Вы уверены, что хотите вывести деньги из системы %username%?",
            moneySuccessfulWithdrawedMessage: "Вы успешно вывели %money% из системы %username%.",
            moneySuccessfulWithdrawedMessageShort: "Вы успешно вывели деньги.",
            withdrawBtn: "Вывести",
            withdrawFromLabel: "Вывести от",
            toReceiverLabel: "Получателю",
            withdrawAllBtn: "Вывести все"
        },
        moneyTransfersTable: {
            usernameHeader: "Имя пользователя",
            amountTableHeader: "Сумма",
            methodTableHeader: "Метод",
            statusTableHeader: "Статус",
            depositWithdrawTableHeader: "Депозит/Вывод",
            dateTimeTableHeader: "Дата/Время",
            moneyTransfersNotFoundMessage: "Денежные переводы не найдены"
        },
        gamesHistory: {
            userNameTableHeader: 'Имя пользователя',
            providerTableHeader: 'Поставщик',
            gameTableHeader: 'Игра',
            betAmountTableHeader: 'Ставка',
            winAmountTableHeader: 'Выигрыш',
            dateTimeTableHeader: 'Дата/Время',
            gamesHistoryNotFoundMessage: 'Записи об истории игр не найдены'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Нет доступных методов оплаты",
            paymentMethodsDropdownLabel: "Методы оплаты",
            yourPaymentAccountsTitle: "Ваши счета для оплаты",
            addPaymentMethodBTN: "Добавить метод оплаты",
            paymentAccountSuccessfullyUpdated: "Вы успешно обновили свой счет для оплаты.",
            depositRateLabel: 'Коэффициент депозита',
            depositRateSavedMessage: 'Коэффициент депозита был успешно сохранен.'
        },
        approveTransaction: {
            userRequestPayoutTitle: "Пользователь %username% запросил выплату %money% на %payment_system%",
            userRequestPaymentTitle: "Пользователь %username% перевел платеж %money% на %payment_system% для пополнения своего баланса.",
            confirmPayoutMessage: "Вы подтверждаете транзакцию?",
            declineBTN: "Отклонить",
            approveBTN: "Подтвердить"
        },
        requestPayment: {
            choosePaymentAccountTitle: "Выберите счет для оплаты\n для пополнения баланса",
            paymentAmountLabel: "Сумма оплаты",
            youllReceiveOnBalanceLabel: "Вы получите на баланс",
            depositRateHelper: "Ставка депозита %rate%",
            backBtn: "Назад",
            nextBtn: "Далее",
            iTransferMoneyBtn: "Я перевожу деньги",
            transferMoneyToThisAccountMessage: "1. Переведите деньги на этот счет",
            enterYourProofMessage: "2. Введите свое подтверждение для проверки вашего платежа"
        },
        securityLog: {
            logID: "ID",
            userName: "Имя пользователя",
            userIP: "IP пользователя",
            logAction: "Действие",
            requestJSON: "Запрос JSON",
            responseJSON: "Ответ JSON",
            timestamp: "Дата/Время",
            securityLogNotFoundMessage: "Записи в журнале безопасности не найдены"
        },
        royaltyStatistics: {
            royaltyStatisticsTitle: "Статистика роялти",
            providerTableHeader: "Поставщик",
            sectionTableHeader: "Раздел",
            currencyTableHeader: "Валюта",
            userLossesTableHeader: "Потери пользователя",
            userWinsTableHeader: "Выигрыши пользователя",
            ggrTableHeader: "GGR",
            royaltyStatisticsNotFoundMessage: "Записи статистики роялти не найдены"
        },
        manageUsers: {
            usersTreeSwitcher: "Дерево пользователей",
            createUserBtn: "Создать пользователя",
            activeTableHeader: "Активен",
            usernameHeader: "Пользователь",
            firstNameTableHeader: "Имя",
            lastnameTableHeader: "Фамилия",
            balanceTableHeader: "Баланс",
            currencyTableHeader: "Валюта",
            roleTableHeader: "Роль",
            emailTableHeader: "Email",
            phoneTableHeader: "Телефон",
            operationsTableHeader: "Операции",
            dateTableHeader: "Дата",
            commissionTableHeader: "Комиссия",
            actionsTableHeader: "Действия",
            usersNotFoundMessage: "Пользователи не найдены"
        },
        timeFilter: {
            fromTimeDefaultLabel: "Время от",
            toTimeDefaultLabel: "Время до",
            fromTimeLabel: "От %time%",
            toTimeLabel: "До %time%",
            todayBTN: 'Сегодня',
            yesterdayBTN: 'Вчера',
            weekBTN: 'Неделя',
            monthBTN: 'Месяц',

            date: 'Дата',
            quickDates: {
                today: 'Сегодня',
                yesterday: 'Вчера',
                lastWeek: 'Предыдущая неделя',
                thisWeek: 'Текущая неделя',
                lastMonth: 'Предыдущий месяц',
                thisMonth: 'Текущий месяц',
            }
        },
        usernameFilter: {
            usernameFilterLabel: "Пользователь"
        },
        userRoles: {
            platform: "платформа",
            owner:  "владелец",
            superadmin:  "суперадмин",
            admin:  "админ",
            cashier:  "кассир",
            player:  "игрок",
            agent: "агент"
        },
        currencySwitcher: {
            currencyLabel: 'Валюта'
        },
        bonusConstructor: {
            createBonusBTN: 'Создать бонус',
            bonusConstructorTitle: 'Конструктор бонуса',
            promoCodeLabel: 'Имя промокода',
            promoCodeHelper: 'название промокода, который будет вводиться при запросе бонуса',
            conditionDepositLabel: 'Условия депозита',
            conditionDepositHelper: 'минимальный депозит, необходимый для получения бонуса',
            wagerLabel: 'Вейджер',
            wagerHelper: 'фактор вейджеринга',
            daysValidLabel: 'Действует дней',
            daysValidHelper: 'как долго действует бонус после запроса в днях',
            bonusAmountLabel: 'Сумма бонуса',
            bonusAmountHelper: 'бонусный приз в деньгах',
            startPromoDateLabel: 'Начало промоакции',
            endPromoDateLabel: 'Конец промоакции',
            additionalConditionLabel: "Дополнительное условие",
            additionalConditionHelper: 'дополнительное условие для получение бонуса',
            bonusPercentageLabel: 'Бонусный процент, %',
            bonusPercentageHelper: 'бонусный приз в процентах от депозита',
            freeSpinsLabel: 'Фриспины',
            freeSpinsHelper: 'бонусные фри спины',
            bonusMaxLabel: 'Максимальный бонус',
            bonusMaxHelper: 'верхний лимит бонуса',
            saveBonusBTN: "Сохранить бонус",
            bonusCreateSuccessMessage: 'Бонус успешно сохранен!'
        },
        bonusList: {
            promoLabel: 'Промокод',
            fromTimeLabel: 'Начало',
            toTimeLabel: 'Окончание',
            conditionDepositLabel: 'Депозит',
            additionConditionLabel: 'Доп. условие',
            wagerLabel: 'Вейджер',
            bonusAmountLabel: 'Сумма бонуса',
            bonusPercentageLabel: 'Бонус в процентах',
            bonusFreeSpinsLabel: 'Фри спины',
            bonusMaxLabel: 'Макс. бонус',
            daysValidLabel: 'Действует дней',
            deleteBonusConfirmationMessage: 'Вы уверены, что хотите удалить этот бонус?',
        },
        coinsPackCreator: {
            savePackBTN: 'Сохранить',
            coinsPackCreatorTitle: 'Пакет монет',
            coinsLabel: 'Монеты',
            coinsHelper: 'количество монет',
            priceLabel: 'Цена',
            priceHelper: 'цена монет',
            freeCoinsLabel: 'Бесплатные монеты',
            freeCoinsHelper: 'количество бесплатных монет',
            coinsPackCreateSuccessMessage: 'Пакет монет успешно сохранен!'
        },
        coinsList: {
            idHeader: 'ID', // coin pack ID
            coinsHeader: 'Монеты', // coins
            priceHeader: 'Цена', // price
            freeCoinsHeader: 'Бесплатные монеты', // free coins
            deleteCoinsPackConfirmationMessage: 'Вы уверены, что хотите удалить этот пакет монет?', // 'Are you sure you want to delete this pack?'
            createCoinPackBTN: 'Создать пакет монет' // 'Create coins pack'
        },
        playersAgents: {
            allUsersTab: "Все пользователи",
            playersTab: "Игроки",
            agentsTab: "Агенты",
            depositBtn: "Пополнить",
            withdrawBtn: "Вывести",
            gameHistoryBTN: "История игр",
            editUserBTN: "Редактировать",
            editDepositRate: 'Редактировать ставку',
            noUsersFoundMessage: "Пользователи не найдены",
            userCardBalanceLabel: "Баланс",
            totalDeposits: "Всего депозитов",
            totalWithdrawals: "Всего выводов",
            netDeposit: "Чистый депозит",
            directSwitchLabel: 'Только прямые',
        },
        netwinUsersTable: {
            nameHeader: 'Имя',
            betsHeader: 'Ставки',
            winsHeader: 'Выигрыши',
            netwinHeader: 'Netwin',
            commissionHeader: 'Комиссия',
            percentageHeader: 'Процент',
            noUsersFoundMessage: 'Пользователи не найдены',
        },
        netwinRoyaltyStatisticsTable: {
            providerHeader: 'Провайдер',
            betsHeader: 'Ставки',
            winsHeader: 'Выигрыши',
            netwinHeader: 'Netwin',
            royaltyStatisticsNotFoundMessage: 'Статистика роялти не найдена',
        },
        agentTransfersTable: {
            typeHeader: "Тип",
            amountHeader: "Сумма",
            timeHeader: "Время",
            initiatorUserID: "ID инициатора",
            userIdHeader: "ID пользователя",
            balanceHeader: "Баланс",
            userBalanceBeforeHeader: "Баланс пользователя до",
            userBalanceAfterHeader: "Баланс пользователя после",
            originUserIdHeader: "ID источника",
            originUserBalanceBeforeHeader: "Баланс источника до",
            originUserBalanceAfterHeader: "Баланс источника после",
            initiatorNameHeader: "Инициатор",
            parentNameHeader: "Родитель",
            userNameHeader: "Пользователь",
            transfersNotFoundMessage: "Переводы не найдены",
            allTypeTab: "Все типы",
            depositsTab: "Депозиты",
            withdrawalsTab: "Выводы",
        },
        balanceTooltip: {
            before: 'До',
            after: 'После',
        },
        generalInfo: {
            myBalanceLabel: 'Мой баланс',
            financialDailyWidgetLabel: 'Финансы',
            betsLabel: 'Ставки',
            userBetsLabel: 'Ставки пользователя',
            userLossesLabel: 'Потери пользователя',
            userWinsLabel: 'Выигрыши пользователя',
            totalBetsLabel: 'Ставки',
            totalGGRLabel: 'GGR',
            totalNewUsersLabel: 'Новые пользователи',
            totalActiveUsersLabel: 'Активные пользователи',
            usersDailyWidgetLabel: 'Активность',
            activeUsersLabel: 'Активные',
            newPlayersLabel: 'Новые',
            usersWidgetLabel: 'Пользователи',
            counterWidget: {
                revshare: 'Ревшара',
                agents: 'Агенты',
                betshops: 'Бетшопы',
                cashiers: 'Кассиры',
                players: 'Игроки',
            }
        }
    }
}
